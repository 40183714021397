var entities = require('character-entities-html4')

var characters = {}
var name

module.exports = characters

for (name in entities) {
  characters[entities[name]] = name
}
