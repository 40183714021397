import CounterStyle from '../CounterStyle';

const upperRoman = CounterStyle.additive({
  1000: 'M',
  900: 'CM',
  500: 'D',
  400: 'CD',
  100: 'C',
  90: 'XC',
  50: 'L',
  40: 'XL',
  10: 'X',
  9: 'IX',
  5: 'V',
  4: 'IV',
  1: 'I'
}).withRange(1, 3999);

export default upperRoman;
